// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck -- external library - styled-components types are not working - fix later
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import 'regenerator-runtime/runtime.js';
import { GlobalContextProvider } from 'context/GlobalContextProvider';
import { renderSnippet } from 'lib/analytics/segment';
import { isIntegrationApi, profileConfig } from 'lib/utils/configs';
import { getConfig } from 'lib/utils/getConfig';
import { useGetProfileSdk, useTheme } from 'lib/hooks';
import initializeDataDog from 'lib/utils/initializeDataDog';
import ErrorBoundaryProvider from 'components/common/ErrorBoundaryProvider';
import Layout from 'components/common/Layout';
import GlobalStyle from 'styles/GlobalStyles';
import FontStyles from 'styles/fonts';
import { Brand } from '../constants';

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const [isSegmentLoaded, setSegmentLoaded] = useState(false);
  const snippet = renderSnippet();
  const profile = useGetProfileSdk(profileConfig);
  const config = getConfig();
  const { theme } = useTheme();

  useEffect(() => {
    setSegmentLoaded(true);
  }, []);

  useEffect(() => {
    config && initializeDataDog(config.dataDog);
  }, [config]);

  return (
    <>
      {process.env.NEXT_PUBLIC_BRAND === Brand.FOX && !isSegmentLoaded && (
        <Script dangerouslySetInnerHTML={{ __html: snippet }} />
      )}
      <CookiesProvider>
        <GlobalContextProvider>
          <ErrorBoundaryProvider>
            <ThemeProvider theme={theme}>
              <Layout>
                <Component config={config} profile={profile} {...pageProps} />
                {isIntegrationApi() && (
                  <Integration>Integration API: https://int1.api3.fox.com/v2.0</Integration>
                )}
              </Layout>
              <GlobalStyle />
              <FontStyles />
            </ThemeProvider>
          </ErrorBoundaryProvider>
        </GlobalContextProvider>
      </CookiesProvider>
    </>
  );
};

export default App;

const Integration = styled.p`
  position: fixed;
  bottom: 20px;
  right: 40px;
  opacity: 0.4;
`;
